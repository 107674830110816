import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Top extends Component {
    render() {
      const step = this.props.step;
      const page = this.props.page;

        return (
          <div>
            
            <div className="header_banner"> 
            <h1>Cord Blood and Cord Tissue Banking and Storage Plans
            </h1></div>                      
                        
            {(page != 'congrats')?
            <div className="act_milestone mb-4">
            <div className="container"> 
              <div className="row">
                {/* STEPS */}
                <section id="Steps" className="steps-section">
                  <div className="steps-timeline timelineprogress">
                    <div className="steps-one">
                      <img src={ (this.props.step==1 || this.props.step==2  || this.props.step==3 ) ? 'image/milestone-check.png' : 'image/milestone-1.png'} alt="" className="steps-img" />
                      <h3 className="steps-name">
                        Banking and Storage Options
                      </h3>
                    </div>
                    <div className="steps-two">
                      <img src={ (this.props.step==2 || this.props.step==3 ) ? 'image/milestone-check.png' : 'image/milestone-2.png'} alt="" className="steps-img" />
                      <h3 className="steps-name">
                        Contact Information
                      </h3>
                    </div>
                    <div className="steps-three">
                      <img src={ (this.props.step==3 ) ? 'image/milestone-check.png' : 'image/milestone-3.png'} alt="" className="steps-img" />
                      <h3 className="steps-name">
                        Shipping and Payment Details
                      </h3>
                    </div>
                  </div>{/* /.steps-timeline */}
                </section>
              </div>
              </div>
          </div>
              :''}
           
          </div>
    );
    }
}

Top.propTypes = {

};

export default Top;