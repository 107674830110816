export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_LANGUAGES = "SET_LANGUAGES";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_INDUSTRIES = "SET_INDUSTRIES";
export const SET_DOMAINS = "SET_DOMAINS";
export const SET_USER_CHATBOTS = "SET_USER_CHATBOTS";
export const PUBLIC_CHATBOT = "PUBLIC_CHATBOT";
export const PUBLIC_ROOM = "PUBLIC_ROOM";
export const ROOM_LIST = "ROOM_LIST";
export const SET_COUNTRY = "SET_COUNTRY";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_INDUSTRY = "SET_INDUSTRY";
export const SET_DOMAIN = "SET_DOMAIN";
export const SET_TABLE= "SET_TABLE";
export const TABLE_LIST = "TABLE_LIST";
export const TAB_LIST = "TAB_LIST";
export const FIELD_LIST = "FIELD_LIST";
export const SET_FIELD = "SET_FIELD";
export const SET_DYNAMIC_VIEW= "SET_DYNAMIC_VIEW";
export const DYNAMIC_VIEW_LIST= "DYNAMIC_VIEW_LIST";
export const DYNAMIC_VIEW_FIELD= "DYNAMIC_VIEW_FIELD";
export const DYNAMIC_VIEW_DETAIL_DATA= "DYNAMIC_VIEW_DETAIL_DATA";
export const DYNAMIC_VIEW_LIST_DATA= "DYNAMIC_VIEW_LIST_DATA";
export const DYNAMIC_VIEW_REFERENCE_LIST_DATA= "DYNAMIC_VIEW_LIST_DATA";
export const LIST_VIEWS= "LIST_VIEWS";
export const SET_PAY= "SET_PAY";
export const MAKE_PAY= "MAKE_PAY";
export const PROMO_CODE= "PROMO_CODE";
export const SEND_MAIL= "SEND_MAIL";





// export const TABLE_LIST = "TABLE_LIST";

