import { SET_PAY, MAKE_PAY,PROMO_CODE } from "../actions/types";

const isEmpty = require("is-empty");

const initialState = {
  pay: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_PAY:
      return {
        ...state,
        page_name: action.payload
      };
      case MAKE_PAY:
        return {
          ...state,
          page_name: action.payload
        };
      case PROMO_CODE:
          return {
            ...state,
            promo_code: action.payload
          };
    default:
      return state;
  }
}
