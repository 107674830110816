import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import validator from 'validator';
import MainLayout from "../layout/main-layout";
import Top from "../layout/Top";
import Header from "../layout/Header";
import SignatureCanvas from 'react-signature-canvas'
import { addAgreement } from '../../actions/payActions'



class Agreement extends Component {

    constructor(){
        super();
        this.state={
            error:'',
            errors:{},
            formData:{},
            trimmedDataURL:null,
            message:'',
            loading:false,           
        }

    }


    componentWillReceiveProps(nextProps) {
        window.scrollTo(0, 0)
        this.setState({ loading: false });  
        this.setState({ loading: false });  
    if(nextProps.page_name.page_name.msg){
        this.setState({message:nextProps.page_name.page_name.msg});
        this.setState({ loading: false }); 
        this.setState({formData:{}})    
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
          );      
        // let element = 
    }  
    }


    onChange = (e) => {  
        this.state.formData[e.target.id] = e.target.value; 
        console.log('form vlaue=>', this.state.formData); 
        e.target.className = "form-control" 
    }

    handleFileRead = async (event) => {
        const file = event.target.files[0]
        if(file.type == "application/pdf"){
        const base64 = await this.convertBase64(file)
        console.log(file.type);
        this.state.formData['Attachment'] = base64;
        event.target.className = "form-control" 
        }
        else{
            this.state.errors["Attachment"] = "Invalid file format.";
            this.state.formData['Attachment']="";
            
        }

    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
    }
    clear = () => {
        this.sigPad.clear()
        this.setState({ trimmedDataURL: null })
      }
    
      trim = () => {
        this.setState({ trimmedDataURL: this.sigPad.getTrimmedCanvas()
          .toDataURL('image/png') })
          this.state.formData['Signature'] = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
          console.log('img', this.sigPad.getTrimmedCanvas().toDataURL('image/png'));
          
      }

    onChangeCheck = (e) => {
    let isChecked = e.target.checked;
    console.log('ischeck -->',isChecked)
    this.state.formData[e.target.id] = isChecked;
    console.log('form vlaue=>', this.state);
    };

    onSubmit = (e) => {              
        e.preventDefault(); 
        let fields = this.state.formData;
        let errors = {};
        let formIsValid = true;
        // let scroll = {};
        const yOffset = -30;  

        // this.trim();

        if(fields["Email"] && !validator.isEmail(fields["Email"])) {
            formIsValid = false;
            errors["Email"] = "Invalid email.";
            let element = document.getElementById("Email");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["actterms"]){
            formIsValid = false;
            errors["actterms"] = "Please check";
            let element = document.getElementById("actterms");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }

        if(!fields["signterms"]){
            formIsValid = false;
            errors["signterms"] = "Please Select any one";
            let element = document.getElementById("signterms");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }

        

        if(!fields["Signature"]) {
            formIsValid = false;
            errors["Signature"] = "Please sign and click on check button.";
            console.log('errors',errors);
            let element = document.getElementById("Signature");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }
        
        if(!fields["Country"]){
            formIsValid = false;
            errors["Country"] = "Cannot be empty";
            let element = document.getElementById("Country");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"              
        }

        if(!fields["Attachment"]){
            formIsValid = false;
            errors["Attachment"] = "Please select a pdf file";
            let element = document.getElementById("Attachment");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"              
        }
        
        if(!fields["State"]){
            formIsValid = false;
            errors["State"] = "Cannot be empty";
            let element = document.getElementById("State");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"              
        }

        if(!fields["Zip"]){
            formIsValid = false;
            errors["Zip"] = "Cannot be empty";
            let element = document.getElementById("Zip");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"              
        }
        
        if(isNaN(fields["Zip"])){
            formIsValid = false;
            errors["Zip"] = "Invalid Zip Code";
            let element = document.getElementById("Zip");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
         } 

        if(!fields["City"]){
            formIsValid = false;
            errors["City"] = "Cannot be empty";
            let element = document.getElementById("City");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"              
        }

        if(!fields["Street"]){
            formIsValid = false;
            errors["Street"] = "Cannot be empty";
            let element = document.getElementById("Street");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"              
        }

        if(!fields["FacilityPhone"]){
            formIsValid = false;
            errors["FacilityPhone"] = "Cannot be empty";
            let element = document.getElementById("FacilityPhone");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"              
        }

        if(isNaN(fields["FacilityPhone"])){
            formIsValid = false;
            errors["FacilityPhone"] = "Invalid Phone Number";
            let element = document.getElementById("FacilityPhone");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
         }  


        if(!fields["FacilityName"]){
            formIsValid = false;
            errors["FacilityName"] = "Cannot be empty";
            let element = document.getElementById("FacilityName");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"              
        }

        if(!fields["FacilityEmail"]){
            formIsValid = false;
            errors["FacilityEmail"] = "Cannot be empty";
            let element = document.getElementById("FacilityEmail");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"              
        }

        if(fields["FacilityEmail"] && !validator.isEmail(fields["FacilityEmail"])) {
            formIsValid = false;
            errors["FacilityEmail"] = "Invalid email.";
            let element = document.getElementById("FacilityEmail");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error" 
        }
        

        if(!fields["PhoneNumber"]){
            formIsValid = false;
            errors["PhoneNumber"] = "Cannot be empty";
            let element = document.getElementById("PhoneNumber");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"              
        }

        if(isNaN(fields["PhoneNumber"])){
            formIsValid = false;
            errors["PhoneNumber"] = "Invalid Phone Number";
            let element = document.getElementById("PhoneNumber");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
         }  

        if(!fields["Email"]){
            formIsValid = false;
            errors["Email"] = "Cannot be empty";
            let element = document.getElementById("Email");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"              
        }

        if(!fields["lastName"]){
            formIsValid = false;
            errors["lastName"] = "Cannot be empty";
            let element = document.getElementById("lastName");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
              
        }

        if(!fields["firstName"]){
            formIsValid = false;
            errors["firstName"] = "Cannot be empty";  
            // this.myRef=  this.myRef.current.scrollIntoView();  
            let element = document.getElementById("firstName");  
            console.log('element',element);            
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'}); 
            element.className = "form-control error"
            
        }      
        this.setState({errors: errors});
        if(formIsValid){
            this.setState({ loading: true });  
            const newList = this.state.formData;
            console.log("newlist=>", newList);
            this.props.addAgreement(newList, this.props.history);
        }
        

    }
    render() {
        const { trimmedDataURL,errors,loading,message } = this.state;
        return (
            <MainLayout>
                <title>Newborn Physician Stem Cell Agreement - American Cell Technology</title> 
                {/* <Header></Header> */}
                <div>            
                    <div className="header_banner"> 
                    <h1>Newborn Physician Stem Cell Agreement</h1></div>
                </div> 
                <div className="container">    	
                <div className="">
                {
                    (message)?<span style={{color:'red'}}>

                    <div className="Congratulations mt-4 mb-4">
                    <h1 className="text-center"> 
                    <i className="fas fa-check-circle" /> <br />
                    <strong> Thank You.</strong> Your completed agreement has been submitted. Please check your email for confirmation details <br /><br />
                    We look forward to working with you <br /><br />
                    <img src="/image/logo.png" alt=" " />
                    </h1>
                    </div>
                    </span>:
                    <form className="needs-validation mt-3 mb-4" noValidate onSubmit={this.onSubmit}>
                <div className="col-md-9 order-md-1 mt-4 act-form">
                <div className="">
                {/* <div>
                    <p className="p1"><span className="s1"><b>PHYSICIAN/PRACTITIONER&nbsp;ATTACHMENT TO</b></span></p>
                    <p className="p1"><span className="s1"><b>STEM CELL PROCESSING AND STORAGE AGREEMENT</b></span></p>
                  
                    <p className="p3" style={{textAlign: 'left'}}><span className="s1">The undersigned physician/practitioner (“Physician”) herby agrees to the terms set forth in this Physician Attachment to the Stem Cell Processing and Storage Agreement (the “Attachment”):</span></p>
                    
                    <p className="p1" style={{textAlign: 'center'}}><span className="s2"><b>RECITALS</b></span></p>

                    <p className="p1" style={{textAlign: 'left'}}><b>A.</b>&nbsp;<span style={{wordSpacing: 'normal'}}>American Cell Technology, a Florida corporation (“Bank”) and the client of Physician named therein (the “Client”), have entered into a STEM CELL PROCESSING AND STORAGE AGREEMENT (the “Storage Agreement”) for the testing, processing, expanding, freezing, storing, withdrawing, transferring, donating, shipping, transporting and/or other use of the Client’s adipose, muscle and/or bone marrow tissue to be collected by the Physician (the “Tissue”), for the purpose of extracting and storing the stem cells contained in the Tissue (collectively, the “Tissue Banking Services”).</span></p>
                    <p className="p1" style={{textAlign: 'left'}}><b>B.</b>&nbsp;<span style={{wordSpacing: 'normal'}}>Physician is willing to provide certain information to the Client and to collect the Tissue from the Client in conjunction with the performance of a liposuction procedure and/or other surgical procedure to be performed on the Client by Physician, in accordance with the terms and conditions set forth in this Attachment.</span></p>
                    <p className="p1" style={{textAlign: 'left'}}><span style={{wordSpacing: 'normal'}}><br /></span></p>
                    <p className="p1" style={{textAlign: 'left'}}><span style={{wordSpacing: 'normal'}}>NOW, THEREFORE, in consideration of the execution and delivery of this Attachment, the Storage Agreement and other good and valuable consideration, the </span>receipt<span style={{wordSpacing: 'normal'}}>&nbsp;and sufficiency of which are hereby acknowledge, Bank and Physician hereby agree as follows:</span></p>
                    <p className="p1" style={{textAlign: 'left'}}><span style={{wordSpacing: 'normal'}}><br /></span></p>
                    <p className="p1" style={{textAlign: 'left'}}><span style={{wordSpacing: 'normal'}}><b>1.</b> <span style={{fontWeight: 'bold'}}><u>Recitals; Defined Terms.</u>&nbsp;</span>The foregoing recitals are true and correct and are incorporated herein by this reference. Unless the context otherwise requires, all initial capitalized terms used but not defined in this Attachment shall have the meaning or meaning given to such terms in the Storage Agreement.</span></p>
                    <p className="p1" style={{textAlign: 'left'}}><b>2.</b> <b><u>Services of Physician.</u></b> Physician agrees to:</p>
                    <blockquote style={{margin: '0 0 0 40px', border: 'none', padding: '0px'}}>
                    <p className="p1" style={{textAlign: 'left'}}><b>a.</b> Inform the Client regarding the risks of the procedure for collecting the Tissue, as well as the risks and benefits of cryopreservation and transplantation of stem cell;</p>
                    <p className="p1" style={{textAlign: 'left'}}><b>b.</b> Collect the Tissue from the Client using a banking ("Banking Kit") furnished by Bank to Physician to store the collected Tissue, provided Physician deems it medically appropriate to do, in the exercise his/her professional medical judgement; and</p>
                    <p className="p1" style={{textAlign: 'left'}}><b>c.</b> Make arrangements to deliver, and pay for the delivery of, the collected Tissue to Bank, in accordance with the instructions provided by bank to Physician.</p>
                    </blockquote>
                    <p>
                    <b>3. <u>Bank and Physician Fees.</u></b><span><b><u> Collection Fees.</u></b> Physician&nbsp;agrees to collect the fees for the collection of the Tissue by Physician and Bank's fee for the Tissue Banking Services from the Client. For each tissue sample collected from each Client, Physician shall remit $2500 for Adipose and $3000 for Bone Marrow samples to the Bank as consideration for the Tissue Banking Services to be provided by Bank to the Client, prior to or concurrently with the shipment of the collected Tissue to Bank. Physician may retain the balance of the fee collected from the Client as compensation for Physician's services to the Client in connection with the collection and delivery of the Tissue.</span></p>
                    <p><b>4. <u> Relationship of the Parties.</u></b><span> Physician acknowledges and agrees that he/she is an independent contractor of Bank and nothing contained herein shall be construed so as to give Physician the authority to bind Bank, or make representations, promises, guarantees, warranties, covenants, contracts, agreements, or otherwise act for or on behalf of Bank, and nothing contained herein shall be construed so as to make Physician the agent, representative, employee, joint venturer or partner of Bank.</span></p>
                    <p><b>5. <u>Release from Liability.</u></b><span> Physician hereby, for him or herself, his or her agents, attorneys, successors-in-interest, affiliates, representative, heirs and assigns, irrevocably and unconditionally releases and discharges Bank and its shareholders, directors, officers, employees, agents, representatives, affiliates, and/or their respective legal representatives, estates, successors and assigns, from and against any and all claims, causes of action, rights, liabilities, damages, costs, and expenses, known and unknown, that Physician, or his or her heirs, assigns, or representatives may incur, resulting from or relating to the testing, processing, expanding, freezing, storing, withdrawing, transferring, donating, shipping, transporting and/or other use of the Client's Tissue. Without limiting the foregoing, Physician further acknowledges that Bank is not responsible in any way for the actions oh Physician and/or others, including, as applicable and without limitation, the medical facility, the staff of the medical facility, laboratory staff, and transporters of the Tissue. Physician understands that by agreeing to this Release from Liability he or she is giving up rights&nbsp;they might otherwise have now or in the future to seek money damages or other remedies or relief from Bank, its affiliates, agents, managers and other persons and entities referenced in this Release from Liability.</span></p>
                    <p><span><b>6. <u>Indemnification.</u></b> Physician agrees to indemnify and hold harmless Bank and its officers, shareholders, directors, employees, agents (including marketing agents), representatives, affiliates, and/or their respective legal representatives, estates, successors and assigns (Bank and each other person or entity being referred to herein as an "Indemnified Person"), from and against all claims, liabilities, losses or damages (or actions in respect thereof) or other expenses that:&nbsp;<br /></span></p>
                    <blockquote style={{margin: '0 0 0 40px', border: 'none', padding: '0px'}}><span><b>a.</b> Are related to or arise out of actions taken or omitted to be taken by Physician, its agents or its employees (including any untrue statements made or any statements omitted to be made); or</span></blockquote>
                    <blockquote style={{margin: '0 0 0 40px', border: 'none', padding: '0px'}}><span><b>b.</b> Are otherwise related to or arise out of actions taken by Physician, its agents or its employees in connection with the information provided to, and the collection of the Tissue from, the Client.</span></blockquote><br/>
                    <p><b>7. <u>Assignment;Delegation.</u></b><span> Bank may assign its rights and delegate its obligations under this Attachment at any time and in its sole discretion, without further consent of Physician. Bank may perform its obligations hereunder through subcontractors, agents or managers pursuant to management services agreements. This Attachment is not assignable by Physician without the prior written consent of Bank and any attempted assignment in breach of the foregoing restriction shall be void.</span><br /><br /><span>By signing below, Physician/Practitioner&nbsp;confirms that he/she has read and fully understands the terms and conditions of this Physician Attachment to the Stem Cell Processing and Storage Agreement.</span><br /></p>
                    <blockquote style={{margin: '0 0 0 40px', border: 'none', padding: '0px'}}><br /></blockquote>
                    <style type="text/css" dangerouslySetInnerHTML={{__html: "\n   li.li1 {margin: 0.0px 0.0px 0.0px 0.0px; font: 15.0px 'Helvetica Neue'; color: #000000}\n   span.s1 {font-kerning: none}\n   ol.ol1 {list-style-type: upper-alpha}\n" }} />
                    <style type="text/css" dangerouslySetInnerHTML={{__html: "\n   li.li1 {margin: 0.0px 0.0px 0.0px 0.0px; font: 15.0px 'Helvetica Neue'; color: #000000}\n   span.s1 {font-kerning: none}\n   ol.ol1 {list-style-type: upper-alpha}\n" }} />
                </div> */}
                    <div className="main-container">
<h1>Newborn Physician Stem Cell Agreement</h1>
<br />
<p style={{marginLeft:'20px'}}>This is an agreement by and between American Cell Technology, a Florida Limited Liability Company <strong>(“ACT”)</strong> and physician <strong>(“Physician”) </strong>
 regarding the collection of Umbilical Cord Tissue (or other perinatal tissue) and Umbilical Cord Blood from patients under the 
Physician’s care and its delivery to ACT for processing and storage.</p>

<div>
<ul className="list-style-1">
<li><strong>DEFINITIONS.</strong>

    <p>When used with an initial capital letter, the following words have the meanings given below:</p>
    <p>“Agreement” means the agreement memorialized in this document, which has the title ACT/Physician Stem Cell Agreement, and all attachments to this document that are identified below as being part of the Agreement.</p>
    <p>“ACT” means American Cell Technology, a Florida Limited Liability Company.</p>
    <p>“ACT Services” means the Umbilical Cord Tissue (or other perinatal tissue) and Umbilical Cord Blood processing, Mesenchymal Stem Cell (MSCs) storage and related services provided by ACT.</p>
    <p>“Child” means the newborn person whose Umbilical Cord Tissue (or other perinatal tissue) and Umbilical Cord Blood are collected and delivered to ACT under this Agreement.</p>
    <p>“Banking Kit” means a kit provided to the Responsible Person by ACT that is to be used by Physician to collect the Umbilical Cord Tissue (or other perinatal tissue) and Umbilical Cord Blood , when the Child is born.</p>
    <p>“Cord Blood” means the Child’s umbilical cord blood as collected by the Physician from the umbilical cord and/or placenta.</p>
    <p>“Cord Tissue” means the umbilical cord tissue that contains stem cells contained in the umbilical cord.</p>
    <p>“Perinatal Tissue” include Placenta, Amniotic Membrane, Amniotic Fluid, and Umbilical Cord Tissue.</p>
    <p>“Enrollment Documents” means all of the forms in the enrollment packet provided to the Responsible Person and the Physician by ACT that are applicable to collection of the Cord Blood, and, if applicable, the Cord Tissue and Placenta, when the Child is born.</p>
    <p>“Physician” means the health care provider who has primary responsibility for supervising the delivery of the Child. </p>
    <p>“Physician Services” means Responsible Person education and collection of Umbilical Cord Tissue (or other perinatal tissue) and Umbilical Cord Blood when the Child is born, as set forth herein.</p>
    <p>“Placenta” means an organ that develops in the uterus during pregnancy and can be collected after the birth of the Child.</p>
    <p>“Responsible Person” means the genetic parent or legal guardian of the Child, who is legally responsible for making decisions on behalf of the Child during his or her minority.</p>
    <p>“Mesenchymal Stem Cells (MSCs)” means multipotent stem cells that are present in multiple tissues, including umbilical cord tissue, cord blood, placenta, bone marrow and fat tissue. </p>

</li>

<li><strong>PHYSICIAN SERVICES</strong>
    <p>Physician agrees to the following:</p>
    <ul className="list-style-num">
        <li><strong>Patient Education</strong>
            <p>Physician agrees to educate Responsible Person(s) on the health benefits of stem cell banking and disseminate ACT’s stem cell banking literature to their patients.</p>
        </li>
        <li><strong>Collection</strong>
            <p>Physician agrees to collect Umbilical Cord Tissue (or other perinatal tissue) and Umbilical Cord Blood, when the Child is born and Physician will 
                familiarize him/herself with the ACT Banking Kit and associated procedures. On the day of collection, Physician will complete the Data Collection 
                Sheet within the Banking Kit, as well as package and label the contents appropriately. When collecting Cord Blood, Physician will collect as much 
                blood as possible; this serves the best interest of the patient, maximizing the opportunity for future use. The  minimal accepted amount of 
                Cord Blood is 40 mL (forty milliliters)  The minimal accepted length of Umbilical Cord Tissue is 15 cm. If either Physician or ACT determine 
                it would be helpful, ACT may offer to complete a digital or onsite training in the event collection volumes fall below the annual 
                average collection size.</p>
        </li>
    </ul>
</li>

<li><strong>STIPEND</strong>
    <p>Upon successful collection of the Umbilical Cord Tissue (or other perinatal tissue) and Umbilical Cord Blood and ACT’s 
        receipt of payment from the Responsible Person for stem cell banking, ACT offers the Physician a professional stipend:</p>
    <table>
        <tr>
            <td>Collection Stipend per Child:</td>
            <td>$350</td>
        </tr>
    </table>
    <p>Physician is required to complete a Form W-9 for tax purposes. ACT agrees Physician will be paid thirty (30) days following 
        the close of the calendar quarter in which the collection takes place. Physician will update ACT on address or practice 
        information to ensure successful delivery of payment and Form 1099. </p>
        <p>ACT will not pay any amounts, or provide any other remuneration, to Physician, other healthcare providers or any member of Physician’s office staff, 
            as a reward for any referral of patients or to induce such referrals in the future, or to induce the purchase, or the recommendation or arranging 
            for the purchase of any item or service, in violation of any applicable state or federal laws.  The parties represent and warrant that as of 
            the date this Agreement is executed, the fees negotiated and agreed upon are fair market value for services rendered based upon an arm's 
            length transaction and are consistent with the value of similar services. Furthermore, the parties represent that the fees are not and 
            have not been determined in a manner that takes into account the volume or value of any referrals of business otherwise generated by 
            Physician for ACT, or as an inducement to generate any business revenues for ACT or Physician.</p>
</li>

<li><strong>GENERAL TERMS</strong>
    <p>Physician agrees to the following:</p>
    <ul className="style-alfa">
        <li>Physician is expected to maintain all required state and federal licenses required by law for Physician Services provided. It is the 
            obligation of the Physician to notify ACT in writing of any change in status.
        </li>
        <li>Physician will comply with all state and federal laws in connection with the performance of cord blood and cord tissue collection 
            services, and use his or her reasonable efforts to inform patients that he or she will receive payment from ACT for applicable cord 
            blood and cord tissue collection services.
        </li>
        <li>Physician shall remain aware that ACT may update or otherwise change the Banking Kit and/or the Physician Instructions 
            in the Banking Kit from time to time. ACT shall notify Physician when updated or otherwise new versions of the Banking 
            Kit and/or the related instructions become available.
        </li>
        <li>Physician shall maintain an inventory of Banking Kits, stored at room temperature, to be used only for patients 
            choosing to store their newborn stem cells with ACT or for patients educational purposes. Physician understands 
            that Banking Kits are not for sale.
        </li>
        <li>Physician shall provide the services in accordance with applicable medical standards of care and comply with all 
            instructions, policies, protocols and procedures provided by ACT in effect as of the date of this Agreement and with any 
            amendments or changes to such instructions, policies, protocols and procedures implemented after the effective date of 
            this Agreement and provided to Physician at least thirty (30) days prior to their effective date or as soon as feasible.  
            At all times, have all current and unrestricted licenses or certifications to practice the medical or healthcare 
            profession in which Physician performs cord blood collection services; maintain Professional and General Liability 
            insurance policies (covering Physician and all other personnel providing healthcare services to patients on 
            Physician’s behalf) in keeping with the standard of practice in the community in which Physician provides those services.  
            Physician will furnish to ACT a certificate of insurance demonstrating the insurance requirements set forth above upon request.  
            Physician shall notify ACT at least thirty (30) days in advance of any cancellation of or changes to insurance coverage that 
            will result in a failure to meet the insurance requirements described herein.
        </li>
        <li>Proprietary Information: The Banking Kit and Physician Services and associated documents may contain or
                use proprietary information of ACT and/or third parties, or otherwise be subject to intellectual property 
                rights of ACT and/or third parties. Physician acknowledge that the Banking Kit and Physician Services are 
                for the Responsible Person’s personal use only and agree that they shall not use, nor permit anyone to use, 
                the Banking Kit (including the documentation that accompanies the Banking Kit) or the Physician Services for 
                ny business use or other use that is competitive with ACT’s business.
            </li>
        <li><p>If the Parties to this Agreement cannot resolve a dispute in an informal and expeditious fashion, prior to 
            initiation of an arbitration proceeding, on request of either party, the parties agree to first attempt in good 
            faith to resolve the dispute through mediation, administered by Judicate West Alternative Dispute Resolution (“Judicate West”) 
            before a single mediator who shall have experience in the subject matter of the dispute or by such other mediation procedure 
            that the parties mutually agree to.  The parties shall jointly select the mediator within fifteen (15) days following the 
            commencement of such request for mediation.  If the parties cannot agree upon the mediator within fifteen (15) days 
            following the initiation of mediation, Judicate West shall appoint a mediator according to its rules.  The parties 
            shall share any fees or expenses of the mediator. If mediation is unsuccessful, the dispute may be submitted by 
            either party to arbitration in Sunrise, Florida, before a neutral arbitrator appointed in accordance with the rules 
            of the procedure of Judicate West. at the election of the party first demanding arbitration (“Rules”).  
            The arbitration shall be governed by laws of Florida (including the right to provisional relief ancillary to the 
            arbitration) and the Rules, to the extent consistent with Florida law.  The arbitrator shall permit pre-hearing 
            discovery as reasonably requested by the parties shall make a reasoned, written award and may not award punitive 
            or consequential damages.  The arbitrator’s decision shall be exclusive, final and binding on all parties, their heirs, 
            executors, administrators, successors and assigns, and may be entered and enforced in any court of competent jurisdiction.  
            The arbitrator shall award the prevailing party its attorney and expert witness fees and costs.  The provisions of this 
            section shall survive the expiration or termination of this Agreement regardless of the cause of such termination.
            </p>

            <p>By signing this Agreement, it is understood that the parties are agreeing to substitute one legitimate dispute 
                resolution form (arbitration) for another (litigation), thereby waiving any right to have the dispute resolved 
                in court. Both parties understand that they have the right to seek legal counsel and the advice of an attorney, 
                to review this Agreement, explain it to them in its entirety, and its possible implications to either party. 
            </p>
        </li>
        <li>Mutual Right to Terminate: This Agreement may be terminated by ACT or Physician upon written notice. ACT reserves 
            the right to make changes to the terms of this Agreement upon notification to the Health Care Provider.
        </li>
        <li>Governing Law: This Agreement and all disputes arising out of or related to this Agreement shall be solely and 
            exclusively construed and governed in accordance with the laws of the State of Florida, without applying any law 
            that would result in the application of a different body of law. Any and all suits hereunder shall be brought and 
            resolved solely and exclusively in, and the parties hereby irrevocably consent to the exclusive jurisdiction 
            and proper venue of, the state and federal courts located in Sunrise, Florida, USA, and waive any objections 
            thereto based on any ground including improper venue or inconvenience of forum.
        </li>
        <li>Exclusion of Warranties. Except as expressly set forth in this Agreement, including its attachments, ACT does not make any warranty, and expressly disclaims all other warranties whatsoever, expressed or implied or arising out of a course of conduct or course of dealing, including all warranties of title, performance, use, merchantability, fitness for a particular purpose, non-infringement, or the absence of latent or other defects, whether or not discoverable. No oral or written statement by ACT that is not confirmed in this Agreement shall create any additional warranty. ACT does not warrant or guarantee that the cord blood or cord tissue or the placenta will be successfully collected or processed, or that the stem cells will be successfully preserved or be suitable for transplant or other therapeutic use or use of any other kind.</li>
        <li>Limitation of Liability. Neither party shall be liable to the other for any special, consequential, incidental, punitive, or indirect damages arising from or relating to any breach of this Agreement, regardless of any notice of the possibility of such damages. notwithstanding the foregoing, nothing in this paragraph is intended to limit or restrict the damages available for breaches of the obligations set forth in Paragraph 4f.</li>
        <li>Indemnification. Each party shall defend, indemnify and hold harmless the other party, including affiliates and each of their respective officers, directors, shareholders, employees, representatives, agents, successors and assigns from and against all claims from third parties, and all associated losses, to the extent arising out of (a) a party’s willful misconduct or gross negligence in performing any of its obligations under this Agreement, or (b) a material breach by a party of any of its representations, warranties, covenants or agreements under this Agreement.</li>
    </ul>
</li>

<div>
I accept and agree to engage in this relationship with ACT. I understand the expectations of this Agreement. In the case of dispute, I agree to arbitrate. This Agreement is governed under Florida State Law. I agree to CONFIDENTIALITY in regards to this Agreement, patient information and any ACT proprietary information shared.
</div>

</ul>
</div>
</div>
                
                
                </div>

                <h4 class="mb-3 mt-3 act-blue-title">Physician Information</h4>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="firstName">First name <span className="act_required">*</span></label>
                        <input   variant='outlined' type="text" className="form-control" id="firstName" placeholder="First Name"   onChange={this.onChange} />
                        <span  style={{color: "red"}}  >{this.state.errors["firstName"]}</span>
                        <div className="invalid-feedback">
                        Valid First name is required.
                        </div>
                    </div>                    
                    <div className="col-md-6 mb-3">
                        <label htmlFor="lastName">Last name <span className="act_required">*</span></label>
                        <input type="text"  className="form-control" id="lastName" placeholder="Last Name"    onChange={this.onChange}  />
                        <span style={{color: "red"}}>{this.state.errors["lastName"]}</span>
                        <div className="invalid-feedback">
                        Valid last name is required.
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="PhoneNumber">Phone Number <span className="act_required">*</span></label>
                        <input   variant='outlined' type="text" className="form-control" id="PhoneNumber" placeholder="Phone Number"   onChange={this.onChange} />
                        <span  style={{color: "red"}}  >{this.state.errors["PhoneNumber"]}</span>
                        <div className="invalid-feedback">
                        Valid Phone Number is required.
                        </div>
                    </div>                    
                    <div className="col-md-6 mb-3">
                        <label htmlFor="Email">Email Address <span className="act_required">*</span></label>
                        <input type="text"  className="form-control" id="Email" placeholder="Email"    onChange={this.onChange}  />
                        <span style={{color: "red"}}>{this.state.errors["Email"]}</span>
                        <div className="invalid-feedback">
                        Valid Email is required.
                        </div>                       
                    </div>
                </div>
                <h4 class="mb-3 mt-3 act-blue-title">Facility Information</h4>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="FacilityName">Your Company/Facility Name <span className="act_required">*</span></label>
                        <input   variant='outlined' type="text" className="form-control" id="FacilityName" placeholder="Facility Name"   onChange={this.onChange} />
                        <span  style={{color: "red"}}  >{this.state.errors["FacilityName"]}</span>
                        <div className="invalid-feedback">
                        Valid name is required.
                        </div>
                    </div>   
                    <div className="col-md-6 mb-3">
                        <label htmlFor="FacilityEmail">Facility Email <span className="act_required">*</span></label>
                        <input   variant='outlined' type="text" className="form-control" id="FacilityEmail" placeholder="Facility Email"   onChange={this.onChange} />
                        <span  style={{color: "red"}}  >{this.state.errors["FacilityEmail"]}</span>
                        <div className="invalid-feedback">
                        Valid email is required.
                        </div>
                    </div>                 
                    <div className="col-md-6 mb-3">
                        <label htmlFor="FacilityPhone">Facility Phone <span className="act_required">*</span></label>
                        <input type="text"  className="form-control" id="FacilityPhone" placeholder="Facility Phone"    onChange={this.onChange}  />
                        <span style={{color: "red"}}>{this.state.errors["FacilityPhone"]}</span>
                        <div className="invalid-feedback">
                        Valid Phone is required.
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="Street">Street <span className="act_required">*</span></label>
                        <input   variant='outlined' type="text" className="form-control" id="Street" placeholder="Street"   onChange={this.onChange} />
                        <span  style={{color: "red"}}  >{this.state.errors["Street"]}</span>
                        <div className="invalid-feedback">
                        Valid street is required.
                        </div>
                    </div> 
                </div>
                <div className="row">
                                       
                    <div className="col-md-6 mb-3">
                        <label htmlFor="City">City <span className="act_required">*</span></label>
                        <input type="text"  className="form-control" id="City" placeholder="City"    onChange={this.onChange}  />
                        <span style={{color: "red"}}>{this.state.errors["City"]}</span>
                        <div className="invalid-feedback">
                        Valid city name is required.
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="State">State <span className="act_required">*</span></label>
                        <input type="text"  className="form-control" id="State" placeholder="State"   onChange={this.onChange}  />
                        <span style={{color: "red"}}>{this.state.errors["State"]}</span>
                        <div className="invalid-feedback">
                        Valid State name is required.
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="Zip">Zip Code <span className="act_required">*</span></label>
                        <input   variant='outlined' type="text" className="form-control" id="Zip"  placeholder="Zip Code"   onChange={this.onChange} />
                        <span  style={{color: "red"}}  >{this.state.errors["Zip"]}</span>
                        <div className="invalid-feedback">
                        Valid Zip Code is required.
                        </div>
                    </div>  
                    <div className="col-md-6 mb-3">
                        <label htmlFor="Country">Country <span className="act_required">*</span></label>
                        <input   variant='outlined' type="text" className="form-control" id="Country" placeholder="Country"   onChange={this.onChange} />
                        <span  style={{color: "red"}}  >{this.state.errors["Country"]}</span>
                        <div className="invalid-feedback">
                        Valid Country name is required.
                        </div>
                    </div>                  

                </div>
                <div className="row">
 
                    <div className="col-md-6 mb-3">
                        <label>W-9 Attachment <span className="act_required">*</span></label>
                        <input   variant='outlined' type="file" className="form-control" id="Attachment" placeholder="Country"  onChange={e => this.handleFileRead(e)} />
                        <span  style={{color: "red"}}  >{this.state.errors["Attachment"]}</span>
                        <p>Please attach your completed and signed W-9 form for review <a target="_blank" href="https://www.irs.gov/pub/irs-pdf/fw9.pdf"> W-9 Form Link </a></p>
                        <div className="invalid-feedback">
                        Valid file is required.
                        </div>
                    </div >   

                    <div className="col-md-6 mb-3">
                        <label htmlFor="signterms">I am signing this Agreement for <span className="act_required">*</span></label>
                        <select className="custom-select d-block w-100" id="signterms" onChange={this.onChange} >
                        <option value="">Choose ...</option>
                        <option value="Myself (Physician)">Myself (Physician)</option>
                        <option value="All doctors">All doctors in my practice and I have the authority as their agent to bind those doctors to the terms of this Agreement</option>
                        </select>
                        <span style={{color: "red"}}>{this.state.errors["signterms"]}</span>
                        <div className="invalid-feedback">
                        Valid Relationship required.
                        </div>
                    </div>               
                    
                </div> 
                <div className="row">
                
                </div>

                <div className="custom-control custom-checkbox mb-4 act_nonrefundable">
                    <input type="checkbox" className="custom-control-input" id="actterms" onChange={this.onChangeCheck} />
                    <label className="custom-control-label act_lc" htmlFor="actterms">I agree to American Cell Technology's Newborn Physician Stem Cell Agreement.</label>
                    <br />
                    {/* <label>Please attach your completed and signed W-9 form for review</label> */}
                    <span style={{color: "red"}}>{this.state.errors["actterms"]}</span>
                </div>
                

                {/* <div className="custom-control custom-checkbox mb-4 act_nonrefundable">
                    <input type="checkbox" className="custom-control-input" id="signterms" onChange={this.onChangeCheck} />
                    <label className="custom-control-label act_lc" htmlFor="signterms">I am signing this Agreement for myself or I am signing this Agreement for all doctors in my practice and I have the authority as their agent to bind those doctors to the terms of this Agreement.</label>
                    <br />
                    
                    <span style={{color: "red"}}>{this.state.errors["signterms"]}</span>
                </div> */}

                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label><span id="Signature"   className="act_required">*</span>By signing below, Physician/Practitioner confirms that he/she has read and fully understands the terms and conditions of this Physician Contract. </label>
                        <SignatureCanvas  ref={(ref) => { this.sigPad = ref }} penColor='white' backgroundColor="gray"  canvasProps={{width: 500, height: 100, className: 'sigCanvas'}} />
                        <span style={{color: "red"}}> {this.state.errors["Signature"]}</span>
                        <div>
                            <button className="btn  btn-lg" type="button"  onClick={this.clear}>
                            Clear
                            </button>  &nbsp;
                            <button className="btn  btn-lg" type="button"  onClick={this.trim}>
                            Click to Confirm Signature
                            </button>
                        </div> 
                        {/* {trimmedDataURL? <img src={trimmedDataURL} /> :null}                        */}
                    </div>                            
                </div>                
               
                <div className="row">              
                <div className="col-md-6 mb-3">
                { (this.state.loading)?  <img style={{float:'right'}} src="/image/loader.gif" />:''}
                <button type="submit"   className="btn btn-primary btn-lg" disabled={loading} >
                            <span> Submit</span>
                        </button> 
                    </div>
                </div>
                </div> 
                </form> 
                }
                </div>
                </div>
            </MainLayout>
        );
    }
}

Agreement.propTypes = {
    errors: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    addAgreement:PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    page_name : state.pay,
    errors: state.errors,
  });


export default connect(mapStateToProps, {addAgreement})(Agreement);

