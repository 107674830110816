/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Footer from "./Footer";
import Top from "./Top";



const MainLayout = props => (  
  <div id="page" className="site">       
    {
      props.children
    }      
    {/* <Footer /> */}
  </div>
 
);

MainLayout.propTypes = {
  children: PropTypes.node.isRequired
};


export default MainLayout;