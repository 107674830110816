import axios from "axios";

import { GET_ERRORS, SET_PAY,SET_COUNTRY,MAKE_PAY, PROMO_CODE, SEND_MAIL} from "./types";


// Edit Country
export const connectSalesforce = () => dispatch => {
  console.log('hello')
  axios
    .get("/api/pay/all")    
    .then(res => {
      console.log(res);
    	dispatch({ type: SET_PAY, payload : res.data.page_name })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};

// Add Country
export const addPayment = (paymentData, history) => dispatch => {
  // const [page_name] = useState(0);
  axios
    .post("/api/pay/checkout", paymentData)
    .then(res => 
      {
        // this.setState({page_name:'congrats'}),
        console.log('response --------', res.data)
        dispatch({ type: MAKE_PAY, payload : res.data })
        // history.push("/")
      })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};

export const sendMail = (paymentData, history) => dispatch => {
  // const [page_name] = useState(0);
  axios
    .post("/api/pay/send_email", paymentData)
    .then(res => 
      {
        // this.setState({page_name:'congrats'}),
        console.log('response --------', res.data)
        dispatch({ type: SEND_MAIL, payload : res.data })
        // history.push("/")
      })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};

export const addAgreement = (formData, history) => dispatch => {
  // const [page_name] = useState(0);
  axios
    .post("/api/pay/agreement", formData)
    .then(res => 
      {
        // this.setState({page_name:'congrats'}),
        dispatch({ type: MAKE_PAY, payload : res.data })
        // history.push("/")
      })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};



export const checkPromoCode = (formData, history) => dispatch => {
  // const [page_name] = useState(0);
  axios
    .post("/api/pay/checkPromoCode", formData)
    .then(res => 
      {
        // this.setState({page_name:'congrats'}),
        console.log(formData);
        dispatch({ type: PROMO_CODE, payload : res.data })
        // history.push("/")
      })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};




