import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";


import Home from "./components/template/home";
import Pay from "./components/template/Pay";
import Congratulations from "./components/template/Congratulations";
import Agreement from "./components/template/Agreement";


// import "./App.css";



class App extends Component {
  /*<Navbar />*/
  render() {
    return (
      <Provider  store={store}>
        <Router>
          <div className="App">

            <Route>
              <Switch>
                <Route exact path="/" component={Pay} />
                <Route exact path="/newborn" component={Pay} />
                <Route exact path="/newborn#" component={Pay} />
                <Route exact path="/congratulations" component={Congratulations} />
                <Route exact path="/newborn-physician-agreement" component={Agreement} />
                
              </Switch>
            </Route>        

          </div>
        </Router>
      </Provider>
    );
  }
}
export default App;
