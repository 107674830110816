import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import MainLayout from "../layout/main-layout";
import Top from "../layout/Top";
import Header from "../layout/Header";

class Congratulations extends Component {
    render() {
        return (
            <MainLayout>
                {/* <Header></Header> */}
                <div className="header_banner"> 
                <h1>Cord Blood Banking and Storage Plans
                </h1>
                </div>
                <div className="container">    	
                <div className="row">
                <div className="col-md-10" style={{marginLeft:'10%'}}>
                <div className="Congratulations mt-4 mb-4">
                    <h1 className="text-center"> 
                    <i className="fas fa-check-circle" /> <br />
                    <strong>Congratulations</strong> on completing your online enrollment and taking the 
                    next step to securing your baby’s stem cells for the future!
                    </h1>
                    <hr className="mb-4" />
                    <h2 className="mb-4">Next Steps</h2>
                    <div className="c_steps">
                    <span>1</span>
                    <div className="c_step_des">
                        <p className="c_des_bold">Please check your email order confirmation for the next steps which includes your unique identifier number.</p>
                        <p>If you have any questions, please either email or call us at <a href="mailto:info@vitalcells.com">info@vitalcells.com</a> OR (844) 443-2355.</p>
                    </div>
                    </div>
                    <div className="c_steps">
                    <span>2</span>
                    <div className="c_step_des">
                        <p className="c_des_bold">Notify your medical professional that you are using VitalCells for newborn stem cell banking.</p>
                    </div>
                    </div>
                    
                    <div className="c_steps">
                    <span>3</span>
                    <div className="c_step_des">
                        <p className="c_des_bold">Receive your kit </p>
                        <p>Your kit will come with two main parts:</p>
                        <ol>
                            <li>
                            <b> Sample Collection kit </b> to provide to your medical professional for collection on day of birth.
                                <ul>
                                <li> <b>DO NOT OPEN OR BREAK ANY SEALS TO THE SAMPLE COLLECTION KIT AS THESE STERIL MATERIALS SHOULD ONLY BE MANAGED BY MEDICAL PROFESSIONALS.</b>
                                </li>
                                </ul>
                            </li>
                            <li><b>Bloodwork Collection (red) box </b>inside of the sample collection kit to provide to your medical professional for mother’s blood draw on day of birth.</li>
                            <ul>
                                <li>DO NOT UNSCREW ANY TUBES PROVIDED AS THESE TUBES SHOULD ONLY BE MANAGED BY MEDICAL PORFESSIONALS.</li>
                        <li>All required bloodwork materials are included and labeled within your sample collection kit.
                        <p>*Please have your blood draw medical professional insert the date and time of blood draw on the provided requisition form. </p></li>
                        <li><p>Bloodwork for the child’s mother must be packaged with the sample collection kit. If not, a waiver must be completed prior to processing.</p></li>
                        <li><p>If you do not receive your kit within 5-7 days, please contact VitalCells directly.</p></li>
                            </ul>
                        </ol>
                        
                            
                        
                        
                        
                    </div>
                    </div>
                    <div className="c_steps">
                    <span>4</span>
                    <div className="c_step_des">
                        <p className="c_des_bold">Call the medical courier to pick up the sample collection kit on delivery day.</p>
                        <ul>
                            <li>
                        <p>Further details on your unique identifier number, provider collection instructions, parents’ guide and checklist, shipping details and other information are provided inside the sample collection kit. </p>
                            </li>
                            <li><p>Further details on your unique identifier number, provider collection instructions, parents’ guide and checklist, shipping details and other information are provided inside the sample collection kit. </p>
                            </li>
                        </ul>
                    </div>
                    </div>
                    <div className="c_steps">
                    <span>5</span>
                    <div className="c_step_des">
                        <p className="c_des_bold">Leave it to VitalCells. </p>
                        <ul>
                            <li>
                            <p>Upon receiving your stem cells, VitalCells will send you updates, a banking certificate and pictures of your cells along the way.</p>
                            </li>
                            <li>
                            <p>Your child's stem cells will then graduate to our adult bank, American Cell Technology. You will then be provided portal access to manage your child's stem cell storage.</p>
                            </li>
                        </ul>
                    </div>
                    </div>
                </div>
                </div>
                </div>
                </div>
            </MainLayout>
        );
    }
}

Congratulations.propTypes = {

};

export default Congratulations;